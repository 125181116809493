.modal-header,
.modal-body,
.modal-footer {
    border: 0;
    padding-left: 20px;
    padding-right: 20px;
    @media (min-width: $medium) {
        padding-left: 30px;
        padding-right: 30px;
    }
}
.modal-header {
    padding-top: 40px;
    padding-bottom: 16px;
    align-items: center;
    position: relative;
    justify-content: center;
    .btn-close {
        position: absolute;
        top: 30px;
        right: 30px;
    }
    &.big-header {
        h2 {
            font-size: 20px;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 14px;
            @media (min-width: $medium) {
                font-size: 24px;
            }
        }
    }
}
.modal-dialog {
    max-width: 440px;
    padding: 0 15px;
    &.modal-lg {
        max-width: 540px;
    }
}
.modal-body {
    padding-top: 0;
    padding-bottom: 30px;
    p {
        line-height: 1.6;
    }
}
.modal-footer {
    padding-bottom: 30px;
    padding-top: 0;
    .btn {
        min-width: 135px;
    }
    .btn-link {
        color: $theme-secondary-color;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
    }
}
.modal-content {
    border-radius: 20px;
    border: 1px solid #e2e2e2;
    background: #fff;
    .close {
        font-size: 14px;
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
    .modal-title {
        font-size: 20px;
        font-weight: 500;
        color: $theme-text;
        margin-bottom: 0;
        text-align: center;
        @media (min-width: $medium) {
            font-size: 24px;
        }
    }
}

.forgot-modal {
    .modal-content {
        border-radius: 5px;
        max-width: 525px;
    }
    .modal-header {
        padding: 40px 40px 10px;
    }
    .modal-body {
        padding: 0 40px 0;
    }
    .modal-footer {
        padding: 0 40px 0;
        > * {
            margin: 0;
        }
    }
    .sub-heading {
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 40px;
    }
    .form-group {
        @media (min-width: $extra-extra-large) {
            margin-bottom: 30px;
        }
    }
    .btn-link {
        padding: 0;
        text-decoration: none;
        height: 24px;
        margin: 20px auto 37px;
        font-size: 12px;
        line-height: 24px;
    }
}

.admin-modals {
    .modal-content {
        border-radius: 10px;
        max-width: 390px;
    }
    .modal-header {
        padding: 20px 20px 15px;
        &.text-left {
            align-items: flex-start;
            justify-content: flex-start;
        }
        .btn-close {
            top: 25px;
            right: 25px;
            padding: 1px;
        }
    }
    .modal-title {
        font-size: 16px;
        font-weight: 600;
    }
    .modal-body {
        padding: 0 20px 13px;
        .inner-text {
            margin-bottom: 17px;
        }
    }
    .modal-footer {
        padding: 0 20px 30px;
        > * {
            margin: 0;
        }
    }
    &.modal-md {
        .modal-dialog {
            max-width: 551px;
        }
        .modal-content {
            max-width: 521px;
        }
    }
    &.internet-unavailable {
        z-index: 1000000000;
    }
}
.add-user {
    .modal-dialog {
        max-width: 510px;
    }
    .modal-content {
        border-radius: 10px;
    }
    .modal-body {
        border: 0;
        padding: 0 40px 10px;
    }
    .modal-footer {
        border: 0;
        padding: 0 40px 40px;
        > * {
            margin: 0;
        }
    }
    .modal-header {
        border: 0;
        padding: 40px 40px 30px;
        justify-content: flex-start;
    }
    .form-group {
        margin-bottom: 30px;
    }
    &.add-location {
        .modal-body {
            padding: 0 30px 30px;
        }
        .btn {
            min-width: 160px;
        }
        .modal-dialog {
            max-width: 550px;
        }
    }
    &.event-list {
        .modal-body {
            padding: 0 0 40px;
        }
        .modal-header {
            padding-bottom: 25px;
        }
        .modal-dialog {
            max-width: 550px;
        }
    }
    &.upload-csv-container {
        .modal-body {
            padding-bottom: 16px;
        }
        .modal-footer {
            padding-bottom: 30px;
        }
        .form-group {
            margin-bottom: 16px;
        }
        .down-btn {
            color: #0f5d99;
            font-size: 14px;
            font-weight: 600;
            padding: 0;
            height: auto;
            min-width: auto !important;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.image-modal {
    .modal-content {
        border-radius: 9px;
        border: 0;
        background: transparent;
    }
    .modal-header {
        position: absolute;
        z-index: 9;
        width: 100%;
        top: 0;
        left: 0;
        .btn-close {
            background: url('../../images/close-profile.svg');
            height: 40px;
            width: 40px;
            padding: 0;
            border-radius: 50%;
            opacity: 1;
            &:focus {
                box-shadow: none;
            }
        }
    }
    .modal-body {
        padding: 0;
        min-height: 292px;
    }
    .card-img {
        border-radius: 9px;
    }
}
.max-overflow-container {
    max-height: 300px;
    overflow: auto;
}
.event-list {
    .list-item {
        display: flex;
        padding: 10px 30px 12px;
        border-bottom: 1px solid #eee;
        align-items: center;
        justify-content: space-between;
        &:first-child {
            border-top: 1px solid #eee;
        }
    }
    .heading {
        color: #222;
        font-size: 14px;
        font-weight: 400;
    }
    .text {
        color: #bbb;
        font-size: 12px;
        font-weight: 400;
    }
    .btn-container {
        .btn {
            padding: 0;
            text-decoration: none;
            min-width: none;
            font-size: 14px;
            font-weight: 400;
            & + .btn {
                margin-left: 30px;
            }
        }
    }
    .text-success {
        color: #34c759 !important;
    }
}
.welcome-modal {
    .modal-dialog {
        max-width: 530px;
    }
    .modal-content {
        background: linear-gradient(186deg, #dff6ff 4.71%, #f8fdff 95.29%),
            var(--white, #fff);
    }
    .modal-header {
        padding-top: 47px;
        padding-bottom: 24px;
        img {
            height: 40px;
            width: 40px;
            margin-right: 18px;
            @media screen and (min-width: 768px) {
                height: 48px;
                width: 48px;
            }
        }
        .modal-title {
            font-size: 20px;
            display: flex;
            align-items: center;
            @media screen and (min-width: 768px) {
                font-size: 28px;
            }
        }
    }
    .modal-body {
        padding: 0 30px 34px;
        text-align: center;
        p {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .modal-footer {
        text-align: center;
        padding-bottom: 50px;
        .btn {
            margin: 0 auto;
        }
    }
}
