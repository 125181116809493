.page-inner-title{
  color: $theme-text;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin: 5px 0 30px;
  @media (min-width: $medium) {
    margin: 0 0 30px;
  }
}
.accordion{
  border: 0;
  box-shadow: none;
  border-radius: 0;
  .accordion-item{
    border-radius: 10px;
    background: #FBFBFC;
    margin-bottom: 20px;
    border: 1px solid #E9ECEF;
    &:last-of-type .accordion-button.collapsed{
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .accordion-button{
      border-radius: 10px;
      background: #FBFBFC;
      color: $theme-text;
      font-size: 14px;
      font-weight: 600;
      box-shadow: none;
      padding: 20px;
      &::after{
        content: '';
        width: 27px;
        height: 27px;
        border-radius: 27px;
        background: #E9ECEF url(../../images/chevron-down.svg) no-repeat center;
      }
      &:focus{
        box-shadow: none;
      }
    }
  }
  .accordion-body{
    padding-top: 0;
    padding-bottom: 4px;
    .inner-box{
      padding-top: 14px;
      &:first-child{
        padding-top: 0px;
      }
    }
  }
  .bottom-space{
    margin-bottom: 44px;
  }
  .form-control{
    border-radius: 4px;
  }
}
.check-title{
  color: $theme-text;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.button-footer{
  padding: 10px 0px 30px;
  text-align: right;
  .btn{
    &.invisible{
      padding: 0;
      height: auto
    }
  }
}
.radio-container{
  .form-check{
    margin: 0;
    padding: 0;
  }
  .custom-radio-chip{
    &:not(:last-child){
      margin-right: 5px;
    }
  }
}
.custom-upload-image{
  position: relative;
  border-radius: 20px;
  box-shadow: 0.11923645436763763px 0.2444722205400467px 0.7888000011444092px 0px rgba(0, 0, 0, 0.02), 0.5330570936203003px 1.0929346084594727px 1.8223999738693237px 0px rgba(0, 0, 0, 0.04), 1.3256287574768066px 2.7179558277130127px 4.161600112915039px 0px rgba(0, 0, 0, 0.04), 2.581118583679199px 5.292104721069336px 8.867199897766113px 0px rgba(0, 0, 0, 0.06), 4.383693218231201px 8.98794937133789px 17px 0px rgba(0, 0, 0, 0.08);
  width: 246px;
  height: 155px;
  margin-bottom: 20px;
  .main-image{
    height: 100%;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
  .edit-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    &.circle{
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(10px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      svg{
        height: 22px;
        width: 22px;
      }
    }
  }
  .video-container{
    background: rgba(#000000, 0.8);
    border-radius: 20px;
    overflow: hidden;
    height: 155px;
    video{
      height: 155px;
    }
    .play-container{
      height: 0;
      font-size: 0;
    }
  }
}
.demo-form{
  .inner-header{
    color: #222;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .form-group{
    margin-bottom: 20px;
    @media (min-width: $medium) {
      margin-bottom: 24px;
    }
    @media (min-width: $extra-extra-large) {
      margin-bottom: 30px;
    }
  }
  .save-btn{
    margin-bottom: 20px;
    @media (min-width: $medium) {
      margin-bottom: 30px;
    }
  }
  .input-with-icon{
    .icon{
      top: 18px;
    }
  }
}
.chip-container{
  margin: 10px -2.5px;
}
.video-container{
  video{
    width: 100%;
  }
  .play-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.video-wrap{
  background: rgba(#000000, 0.8);
  overflow: hidden;
  video{
    height: 257px !important;
  }
}
.add-slot-btn{
  text-align: right;
  font-size: 0;
  margin-bottom: 20px;
  margin-top: -5px;
}
.chip-outer-container{
  margin-left: -10px;
  margin-right: -10px;
  .chip{
    margin: 0 10px 20px 10px;
    min-width: 256px;
  }
}
.heading-with-btn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 20px;
  .btn-container{
    .btn{
      min-width: 130px;
      font-weight: 600;
    }
    .btn-link{
      text-decoration: none;
      padding-right: 0;
      min-width: auto;
      margin-left: 12px;
    }
  }
}
.text-with-more{
  color: #222;
  font-size: 16px;
  font-weight: 400;
  .strong-text{
    color: #0F5D99;
    font-weight: 600;
    cursor: pointer;
  }
  .fade-text {
    color: #BBB;
    margin-left: 10px;
    display: inline-block;
  }
}
