.login-container {
    display: flex;
    width: 100%;
}
.login-image-container {
    flex: 1;
    display: none;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (min-width: $large) {
        display: flex;
    }
}
.login-form-container {
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    @media (min-width: $medium) {
        padding: 0 30px;
    }
    @media (min-width: $large) {
        padding: 0 80px;
        max-width: 534px;
        display: flex;
    }
}
.login-form {
    width: 100%;
    text-align: left;
}
.login-inner {
    width: 100%;
    max-width: 373px;
    margin: auto;
    text-align: center;
    position: relative;
    .form-group {
        margin-bottom: 20px;
    }
    .form-control {
        background: rgba(233, 236, 239, 0.2);
    }
    .logo {
        width: 115px;
        margin: 30px auto 40px;
    }
    .login-title {
        display: flex;
        align-items: baseline;
        color: $theme-text;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 24px;
        line-height: normal;
        img {
            margin-left: 6px;
            width: 28px;
        }
    }
    .input-with-icon {
        .icon {
            top: 38px;
            @media (min-width: $extra-extra-large) {
                top: 43px;
            }
        }
    }
    .forgot-container {
        text-align: right;
        margin: -5px 0 40px;
        a {
            color: $theme-secondary-color;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
            text-decoration: underline;
        }
    }
    .btn-container {
        text-align: center;
        margin-bottom: 30px;
        .btn {
            height: 60px;
            width: 100%;
            max-width: 290px;
        }
    }
    .signin-bottom {
        margin-bottom: 30px;
        a {
            color: $theme-secondary-color;
            font-weight: 600;
            text-decoration: underline;
            display: inline-block;
            margin-left: 3px;
        }
        .resend-text {
            color: #707070;
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 16px;
            span {
                color: $theme-secondary-color;
                font-weight: 600;
                display: inline-block;
                margin-left: 3px;
                cursor: pointer;
                text-decoration: underline;
            }
        }
        .resend-bottom-text {
            color: #707070;
            font-size: 12px;
            font-style: italic;
            font-weight: 300;
        }
    }
    &.success {
        .verify-image {
            margin-bottom: 20px;
        }
        .login-title {
            justify-content: center;
            margin-bottom: 10px;
        }
        .verify-subtext {
            text-align: center;
            margin-bottom: 40px;
        }
    }
    .back-btn-container {
        text-align: left;
    }
    .back-btn {
        display: inline-block;
        margin-bottom: 35px;
        text-align: left;
        cursor: pointer;
    }
    &.forgot-pass-container {
        .login-title {
            margin-bottom: 10px;
        }
        .verify-subtext {
            margin-bottom: 20px;
        }
        .form-label {
            display: none;
        }
        .form-group {
            margin-bottom: 200px;
        }
        .btn-container {
            margin-bottom: 0;
        }
    }
    &.verify-container{
        .login-title{
            margin-top: 30px;
            padding: 0 16px;
            @media (min-width: $medium) {
                padding: 0;
            }
        }
    }
}
.password-hint {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -8px 20px;
    &.more-margin{
        margin-bottom: 40px;
    }
    .box {
        display: flex;
        align-items: center;
        color: $grey;
        font-size: 12px;
        font-weight: 400;
        margin: 0 8px 5px;
        svg {
            margin-right: 3px;
        }
        &.active {
            color: $theme-text;
            svg {
                path {
                    stroke: #4db464;
                }
            }
        }
    }
}
.terms-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    color: $theme-text;
    font-size: 13px;
    span {
        line-height: 16px;
        display: inline-block;
    }
    a {
        display: inline-block;
        color: $theme-secondary-color;
        text-decoration: underline;
    }
    .custom-checkbox.small label {
        min-height: 9px;
    }
}
.business-modal {
    background: #f4f4f4;
    box-shadow: 17px -10px 21px 0px rgba(29, 93, 153, 0.15);
    width: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 110;
    padding: 50px 40px 24px;
    color: $theme-text;
    @media (min-width: $large) {
        max-width: 534px;
    }
    .close-btn {
        position: absolute;
        top: 25px;
        right: 40px;
        cursor: pointer;
        padding: 5px;
    }
    .modal-title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
    }
    .address {
        color: $black;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 20px;
    }
    p {
        color: #222;
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 30px;
    }
    .footer-btn {
        text-align: right;
        .btn {
            width: 130px;
        }
        .btn-link {
            color: $theme-secondary-color;
            font-size: 13px;
            font-weight: 400;
            text-decoration: none;
        }
    }
}
.verify-subtext {
    text-align: left;
    color: #707070;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 48px;
    span {
        font-weight: 600;
    }
}
.verify-form {
    .signin-bottom {
        text-align: center;
        margin-bottom: 120px;
        &.less-margin {
            margin-bottom: 86px;
        }
    }
}
.code-inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .form-control {
        max-width: 62px;
        height: 62px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        .was-validated .form-control:invalid,
        &.is-invalid {
            border-width: 1px;
        }
        &:not(:first-child) {
            margin-left: 25px;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }
}
.cancel-btn-container {
    position: absolute;
    top: 30px;
    right: 20px;
    z-index: 1;
    @media (min-width: $medium) {
        top: 70px;
        right: 80px;
    }
    .cancel-btn {
        color: #ff6060;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        display: inline-block;
    }
}
