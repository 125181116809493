.promo-container {
    .page-header {
        margin-bottom: 20px;
    }
}
.promo-box {
    border-radius: 10px;
    border: 1px solid #eee;
    background: #fff;
    padding: 30px;
    margin-bottom: 30px;
}
.promo-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 18px;
}
.promo-list {
    .promo-title {
        margin-bottom: 13px;
    }
    .list {
        border: 1px solid #eee;
    }
}
.users-container {
    .header-with-btn {
        margin-bottom: 24px;
        .page-header {
            margin-bottom: 5px;
            line-height: 150%;
        }
        p {
            margin: 0;
            color: #000;
        }
    }
}
.promocode-container {
    text-align: center;
    padding-bottom: 30px;
    &.adv-form-promo{
        text-align: left;
        padding-bottom: 0;
        margin-top: 10px;
        h2{
            font-size: 20px;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 8px;
        }
        p{
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 8px;
        }
        .input-and-button{
            margin-left: 0;
            margin-bottom: 40px;
        }
        .success-feedback{
            margin-left: 0;
            text-align: left;
            margin-top: -30px;
        }
    }
    p {
        margin-bottom: 26px;
        color: #222;
        font-size: 16px;
        font-weight: 400;
    }
    .input-and-button {
        max-width: 500px;
        margin: 0 auto 60px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .btn-link {
            font-weight: 600;
            text-decoration: none;
        }
    }
    .input-with-icon {
        flex: 1;
        .icon {
            top: 8px;
            right: 12px;
            @media (min-width: $extra-extra-large) {
                top: 13px;
                right: 20px;
            }
        }
    }
    .success-feedback {
        width: 100%;
        color: #34c759;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        margin: -50px auto 40px;
        text-align: center;
        max-width: 650px;
        @media (min-width: $medium) {
            min-width: 517px;
        }
        span {
            font-weight: 700;
        }
    }
}
.promo-list-container {
    min-height: 200px;
    .promo-list {
        border: 1px solid #eee;
        border-radius: 8px;
        margin-bottom: 10px;
        display: flex;
        min-height: 84px;
        padding: 0 16px 0 20px;
        color: #222;
        justify-content: space-between;
    }
    .promo-content {
        display: flex;
        flex: 0 0 calc(100% - 201px);
        max-width: calc(100% - 201px);
    }
    .promo-title {
        margin-bottom: 5px;
        font-weight: 600;
    }
    .promo-fist-content {
        flex: 0 0 65%;
        max-width: 65%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 15px 0;
    }
    .promo-discount {
        flex: 0 0 35%;
        max-width: 35%;
        padding-right: 50px;
        justify-content: flex-end;
        font-size: 16px;
        display: flex;
        align-items: center;
        .content-percent {
            font-weight: 600;
            display: inline-block;
            margin-right: 10px;
        }
    }
    .coupon-content {
        border-left: 1px dashed #bbb;
        display: flex;
        flex: 0 0 200px;
        max-width: 200px;
        justify-content: space-between;
    }
    .list-coupon {
        padding: 30px 35px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
    }
    .coupon {
        color: #0f5d99;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.8px;
    }
    .more-dropdown-form {
        display: flex;
        align-items: center;
        .dropdown-toggle {
            min-width: 0;
            width: auto;
            height: auto;
        }
    }
}
