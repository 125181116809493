.table-container {
  width: 100%;
  position: relative;
  .infinite-scroll-component {
    padding: 0;
  }
}
.table-head {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #fff;
  border: 1px solid #EEE;
  display: flex;
  padding: 20px 20px 5px;
  padding-right: 20px;
  border-bottom: 0;
}
.table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 100%;
  max-width: 100%;
}
.th {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  color: #222;
}

.table-body {
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow: auto;
  min-height: 300px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  .infinite-scroll-component__outerdiv,
  .infinite-scroll-component {
    min-height: 300px;
  }
  .table-row {
    min-height: 62px;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #ececec;
    border-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    flex-wrap: wrap;
    &.inactive{
      background-color: #ececec;
    }
    .td{
      min-height: 62px;
    }
    &:last-child{
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
.td {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: #222;
  word-break: break-word;
}

.user-table{
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 30%;
        max-width: 30%;
      }
      &:nth-child(2) {
        flex: 0 0 45%;
        max-width: 45%;
      }
      &:nth-child(3) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(4) {
        flex: 0 0 10%;
        max-width: 100px;
        justify-content: center;
      }
    }
  }
}
