.center-full-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    background: linear-gradient(
        180deg,
        #dff7ff 0%,
        rgba(255, 255, 255, 0) 100%
    );
}
.new-subs-plan-container {
    background: transparent;
    .subs-inner {
        max-width: 362px;
    }
    .subs-container {
        background-color: $theme-secondary-color;
        padding: 30px;
        color: #fff;
        .heading {
            font-weight: 600;
            margin-bottom: 15px;
        }
        .price {
            margin-bottom: 15px;
            &.price-cut{
                margin: 0 0 15px !important;
            }
        }
        p {
            margin-bottom: 20px;
            padding: 0 6px;
        }
        .check-list {
            margin-bottom: 32px;
            li {
                margin-bottom: 24px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .btn-link {
            color: #ff6060;
            font-size: 13px;
            font-weight: 600;
            text-decoration: none;
            padding: 0;
            height: auto;
            margin-bottom: 0;
        }
        .note-container{
            margin: 0 -31px 15px;
            border-radius: 0;
            border: 0;
            padding: 10px 36px;
            text-align: center;
            font-size: 13px;
            color: #2F3031;
        }
        .renew-text{
            margin-top: 20px;
        }
    }
}
.subs-inner {
    width: 100%;
    max-width: 750px;
    margin: auto;
    padding-bottom: 30px;
    &.buy-plan {
        .subs-container {
            max-width: 420px;
            text-align: left;
            border-radius: 30px;
            border-color: #eee;
            padding: 30px 30px 40px;
            .heading {
                text-align: left;
                color: #0f5d99;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 4px;
                text-transform: uppercase;
                margin-bottom: 4px;
            }
            .price {
                text-align: left;
                margin-bottom: 15px;
                &.strikethrough {
                    text-decoration: line-through;
                    font-size: 16px;
                }
            }
            p {
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 20px;
                color: #222;
            }
            .btn.btn-primary {
                max-width: 240px;
                margin: 0 auto;
            }
        }
    }
    &.business-plan {
        max-width: 100%;
        .subs-container {
            margin-left: 0;
            .check-list {
                margin-bottom: 0;
            }
        }
    }
}
.subs-container {
    padding: 60px 30px 50px;
    border-radius: 10px;
    border: 1px solid #c5c5c5;
    background: #fff;
    margin: 30px auto 0;
    position: relative;
    &.active {
        .icon-correct {
            display: inline-block;
        }
        .btn-primary {
            background-color: #dff6ff;
            opacity: 1;
            color: $theme-secondary-color;
        }
    }
    .icon-correct {
        position: absolute;
        top: -29px;
        left: 50%;
        transform: translate(-50%);
        display: none;
    }
    .heading {
        text-align: center;
        font-size: 20px;
        text-transform: uppercase;
    }
    .price {
        text-align: center;
        font-size: 20px;
        margin-bottom: 25px;
        span {
            font-size: 40px;
            font-weight: 600;
        }
        &.price-cut {
            font-size: 16px;
            text-decoration: line-through;
            margin: 16px 0 5px !important;
            span {
                font-size: 16px;
                text-decoration: line-through;
            }
        }
    }

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin-bottom: 30px;
    }
    .divider {
        height: 1px;
        width: 100%;
        background: #e1e1e1;
        margin-bottom: 30px;
    }
    .check-list {
        margin-bottom: 85px;
        li {
            padding-left: 25px;
            position: relative;
            font-size: 14px;
            margin-bottom: 30px;
            &::after {
                content: '';
                background: url('../../images/check-green.svg') no-repeat center;
                height: 10px;
                width: 10px;
                position: absolute;
                left: 0;
                top: 4px;
            }
        }
    }
    .renew-text {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
    }
}
.admin-subs-pg {
    margin: 0 0;
    .col-md-6 {
        max-width: 380px;
    }
}
.admin-subs-container {
    padding: 30px 30px 50px;
    border-radius: 20px;
    border: 1px solid #eee;
    background: #fff;
    margin: 2px auto 30px;
    position: relative;
    max-width: 380px;
    p{
        line-height: normal;
    }
    &.adv-plan {
        padding-bottom: 30px;
        .subs-card-head {
            margin-bottom: 0;
        }
    }
    .form-switch {
        padding-left: 0;
    }
    .form-check-label {
        display: none;
    }
    .btn-outline-primary {
        height: 44px;
        position: relative;
        top: -10px;
        right: -10px;
    }
    .subs-card-head {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 10px;
        justify-content: space-between;
        .action {
            display: flex;
        }
        .title {
            max-width: 50%;
            width: 50%;
            span {
                font-size: 14px;
                line-height: normal;
                font-weight: 600;
                text-transform: uppercase;
                color: #0f5d99;
                margin-bottom: 9px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                word-break: break-word;
            }
            h6 {
                font-size: 20px;
                font-weight: 600;
                color: #222;
                line-height: normal;
                .small-text {
                    font-size: 14px;
                    font-weight: 400;
                    color: #222;
                    text-transform: capitalize;
                    display: inline-flex;
                }
            }
        }
    }
    .divider {
        margin: 16px 0;
    }
    .check-list {
        margin-bottom: 20px;
        li {
            padding-left: 25px;
            position: relative;
            font-size: 14px;
            margin-bottom: 15px;
            &::after {
                content: '';
                background: url('../../images/check-green.svg') no-repeat center;
                height: 10px;
                width: 10px;
                position: absolute;
                left: 0;
                top: 4px;
            }
        }
    }
}

.gradient-container {
    width: 100%;
    background: linear-gradient(
        180deg,
        #dff7ff 0.01%,
        rgba(255, 255, 255, 0) 100%
    );
    height: 466px;
    text-align: center;
    h2 {
        color: #222;
        font-size: 28px;
        font-weight: 500;
        line-height: 150%; /* 42px */
        margin: 60px auto 4px;
    }
    p {
        color: #bbb;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin-bottom: 60px;
    }
}
.white-box-outer {
    text-align: right;
    .white-box {
        border-radius: 10px;
        border: 1px solid #eee;
        background: #fff;
        text-align: left;
        padding: 20px 24px;
        margin-bottom: 30px;
        h5 {
            color: #222;
            font-size: 18px;
            font-weight: 500;
            line-height: 150%; /* 27px */
            margin-bottom: 20px;
        }
        .radio-container .custom-radio-chip {
            margin-bottom: 10px;
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
    .no-data {
        text-align: center;
    }
}
.note-container {
    border-radius: 10px;
    background: #dff6ff;
    padding: 8px 10px;
    color: #222;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 20px;
    margin-top: -10px;
}
