.btn{
  // @include font-regular(16px, 1.3);
  border-radius: 58px;
  height: 50px;
  padding: 14px 28px 13px;
  cursor: pointer;
  &.btn-sm{
    border-radius: 0;
    height: auto;
    padding: 5px;
  }
  &.big{
    height: 50px;
    width: 200px;
    padding: 15px 0;
  }
  &.btn-block{
    width: 100%;
  }
  &.mw-130{
    min-width: 130px;
  }
  &.medium{
    width: 190px;
  }
  &.w-260{
    width: 260px;
  }
  &.show:focus-visible{
    box-shadow: none;
  }

  &.btn-primary{
    color: $white;
    border:0;
    border-color: transparent;
    @extend %all-animation;
    background: $primary-button-background;
    font-size: 13px;
    font-weight: 600;

    &:hover,
    &:focus,
    &:active{
      border-color: transparent;
      background-color: #0B4D80;
    }
    &.disabled, &:disabled {
      opacity: 0.5;
    }
    .add-icon{
      display: inline-block;
      margin-right: 9px;
    }
  }
  &.btn-danger{
    border:0;
    border-color: transparent;
    @extend %all-animation;
    font-size: 13px;
    font-weight: 600;
    background: $danger;
    &:hover{
      background: darken($danger, 10);
    }
  }
  &.btn-outline-primary{
    font-size: 13px;
    font-weight: 600;
    color: $btn-link-text;
    border: solid 1px $btn-link-border;
    background-color: $white;
    @extend %all-animation;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 58px;
    img{
      margin-right: 5px;
    }
    &:hover,
    &:focus,
    &:active{
      border-color: $btn-link-text;
      color: $btn-link-text;
      background-color: $white;
    }
    &.disabled, &:disabled {
      opacity: 0.5;
    }
  }
  &.link{
    color: $btn-link-text;
    background: $btn-link-background;
    border-color: $btn-link-border;
    @extend %all-animation;

    &:hover,
    &:focus,
    &:active{
        color: $white;
        background: darken($btn-link-background, 10%);
        border-color: darken($btn-link-border, 10%);
    }
    &.disabled, &:disabled {
      color: rgba($btn-link-text, 0.5);
      background: $btn-link-background;
      border-color: $btn-link-border;
    }
  }
  &.disabled, &:disabled {
    opacity: 1;
  }
  &.btn-link{
    color: $theme-secondary-color;
    &:hover,
    &:focus,
    &:active{
      color: #0B4D80;
    }
    &.text-danger{
      &:hover,
      &:focus,
      &:active{
       color: darken($danger, 10) !important;
      }
    }
  }
}
.add-slot {
  color: #0F5D99;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  padding: 0;
  height: auto;
  margin: 0;
  &.disabled, &:disabled {
    opacity: 0.5;
    color: #0F5D99;
  }
}
