.search-container{
  display: flex;
  width: 100%;
  justify-content: center;
  .search-inner{
    width: 100%;
    max-width: 793px;
    text-align: center;
    padding-top: 76px;
    .logo{
      width: 109px;
      min-width: 109px;
      height: 127px;
      margin: 0 auto 25px;
    }
    .search-title{
      text-align: center;
      color: #000;
      font-size: 30px;
      font-weight: 600;
      margin: 0 0 10px;
    }
    p{
      color:#BBB;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 40px;
    }
  }
}
.autocomplete-search{
  text-align: left;
  .form-control{
    height: 68px;
    border-radius: 34px;
    border: 1px solid #EEE;
    background: #FFF;
    box-shadow: 1.00725519657135px 0.7714684009552002px 0.9828125238418579px 0px rgba(0, 0, 0, 0.01), 4.604595184326172px 3.526712656021118px 2.762500047683716px 0px rgba(0, 0, 0, 0.02), 11.65538215637207px 8.92699146270752px 7.410937309265137px 0px rgba(0, 0, 0, 0.03), 23.02297592163086px 17.633563995361328px 17px 0px rgba(0, 0, 0, 0.04);
    font-size: 18px;
    letter-spacing: 0.36px;
    font-weight: 300;
    &::-webkit-input-placeholder,
    &::placeholder {
      color: #BBB;
      opacity: 1;
      font-weight: 300;
    }
  }
  .icon-right{
    .form-control{
      padding-left: 84px;
      padding-right: 35px;
    }
    .icon{
      right: auto;
      top: 22px;
      left: 40px;
    }
  }
}
.autocomplete-outer{
  margin-top: 5px;
  min-height: 70px;
  border-radius: 34px;
  border: 1px solid var(--border-gray, #EEE);
  background: #FFF;
  box-shadow: 1.00725519657135px 0.7714684009552002px 0.9828125238418579px 0px rgba(0, 0, 0, 0.01), 4.604595184326172px 3.526712656021118px 2.762500047683716px 0px rgba(0, 0, 0, 0.02), 11.65538215637207px 8.92699146270752px 7.410937309265137px 0px rgba(0, 0, 0, 0.03), 23.02297592163086px 17.633563995361328px 17px 0px rgba(0, 0, 0, 0.04);
  padding: 10px 30px 10px;
  max-height: 500px;
  overflow: auto;
  .autocomplete-inner{
    display: flex;
    width: 100%;
    border-bottom: 1px solid #EEE;
    padding: 12px 0 6px;
    &:last-child{
      border-bottom: 0;
    }
    .icon{
      position: static;
      margin-right: 10px;
    }
    .icon-right{
      margin-left: auto;
      margin-top: 10px;
    }
    ul{
      li{
        color: #BBBBBB;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 4px;
        &.business-name{
          color:#222;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.28px;
          margin-bottom: 4px;
        }
        .email-text{
          display: inline-block;
          margin-left: 28px;
        }
      }
    }
  }
}
