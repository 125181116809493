.form-group {
    position: relative;
    margin-bottom: 20px;
    @media (min-width: $medium) {
        margin-bottom: 24px;
    }
    label {
        display: inline-block;
        margin-bottom: 5px;
    }
    &.extra-margin {
        @media (min-width: $extra-extra-large) {
            margin-bottom: 30px;
        }
    }
    &.max-bottom-margin {
        margin-bottom: 200px !important;
    }
}
.form-row {
    margin-right: -10px;
    margin-left: -10px;
    > .col,
    > [class*='col-'] {
        padding-right: 10px;
        padding-left: 10px;
    }
}
label {
    font-size: 14px;
    font-weight: 400;
    color: $input-label-color;
    margin-bottom: 5px;
    line-height: 20px;
}
.invalid-feedback {
    color: $error-color;
    margin-top: 6px;
    display: block;
    align-items: center;
    line-height: 1.2;
}
.form-control {
    background: $input-background;
    border: 1px solid $input-border-color;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    color: $input-text;
    padding: 0 20px;
    font-size: 16px;
    text-size-adjust: none;
    @media (min-width: $small) {
        font-size: 14px;
    }
    @media (min-width: $extra-extra-large) {
        height: 60px;
    }
    &::-webkit-input-placeholder,
    &::placeholder {
        color: $input-placeholder;
        opacity: 1;
    }
    &:focus {
        color: $input-text;
        background-color: $input-background;
        border-color: $input-focus-border-color;
        outline: 0;
        box-shadow: none;
    }
    .was-validated .form-control:invalid,
    &.is-invalid {
        border-width: 2px;
        border-color: $input-border-color;
        background-image: none;
        &:focus {
            border-color: $input-border-color;
            box-shadow: none;
        }

        ~ .invalid-feedback,
        ~ .invalid-tooltip {
            display: flex;
        }
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        box-shadow: 0 0 0px 1000px white inset;
        transition: background-color 5000s ease-in-out 0s;
    }
    &.disabled,
    &:disabled {
        color: #bbb;
        border: 1px solid #e9ecef;
        background: rgba(233, 236, 239, 0.3);
        &::-webkit-input-placeholder,
        &::placeholder {
            color: #bbb;
            opacity: 1;
        }
        &:read-only {
            opacity: 1;
        }
    }
    &:read-only,
    &.read-only {
        opacity: 1;
        color: #bbb;
        border: 1px solid #e9ecef;
        background: rgba(233, 236, 239, 0.3) !important;
        &::-webkit-input-placeholder,
        &::placeholder {
            color: #bbb;
            opacity: 1;
        }
    }
}
textarea {
    &.form-control {
        height: 236px;
        resize: none;
        padding-top: 20px;
        padding-bottom: 20px;
        @media (min-width: $medium) {
            height: auto;
        }
    }
}

.label-heightlight {
    .form-control {
        border-color: $input-focus-border-color;
    }
}

.input-group-text {
    border: 0;
    background: $input-background;
    font-size: 1.5rem;
}
.icon-tick {
    font-size: 74px;
    margin-bottom: 40px;
}

.custom-checkbox {
    margin-bottom: 16px;
    .form-check {
        padding-left: 0;
    }
    .form-check-label {
        margin-bottom: 4px;
    }
    &.small {
        margin-bottom: 0;
        .form-check {
            min-height: 18px;
            margin-bottom: 0;
        }
        input[type='radio'] + label,
        input[type='checkbox'] + label {
            padding-left: 26px;
            color: $theme-text;
            line-height: 24px;
            cursor: pointer;
            &:before {
                content: ' ';
                width: 18px;
                height: 18px;
                border-radius: 3px;
                border: 1px solid #000;
            }
        }
        input[type='checkbox']:checked + label {
            &:before {
                background-size: 85%;
                border-color: $checkbox-checked-background;
            }
        }
    }
}
.custom-select {
    .form-check {
        min-height: 16px;
        margin-bottom: 0;
    }
    input[type='radio'] + label,
    input[type='checkbox'] + label {
        padding-left: 36px;
        color: $theme-text;
        line-height: 18px;
        margin-bottom: 0;
        cursor: pointer;
        &:before {
            content: ' ';
            width: 18px;
            height: 18px;
            border-radius: 3px;
            border: 1px solid #000;
        }
    }
    input[type='checkbox']:checked + label {
        &:before {
            background: $checkbox-checked-background
                url(../../images/check-small.svg) no-repeat center;
        }
    }
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

input[type='radio'],
input[type='checkbox'] {
    @extend .visuallyhidden;
}

input[type='radio'] + label,
input[type='checkbox'] + label {
    position: relative;
    padding-left: 34px;
    color: $theme-text;
    line-height: 24px;
    cursor: pointer;
    &:before {
        position: absolute;
        left: 0;
        top: 0;
        content: ' ';
        display: block;
        width: 24px;
        height: 24px;
        background: $checkbox-background;
        border-radius: 4px;
        border: 1px solid #e9ecef;
    }
}
input[type='radio'] + label {
    &:before {
        border-color: $black;
        border-radius: 50%;
    }
}

input[type='radio']:checked + label {
    &:before {
        background: $white;
        border-color: $checkbox-checked-background;
        border-width: 2px;
    }
    &::after {
        position: absolute;
        left: 5px;
        top: 5px;
        content: ' ';
        display: block;
        width: 14px;
        height: 14px;
        background: $checkbox-checked-background;
        border-radius: 50%;
    }
}
input[type='checkbox']:checked + label {
    &:before {
        background: $checkbox-checked-background url(../../images/check.svg)
            no-repeat center;
        border-color: $checkbox-checked-background;
    }
}
.custom-radio-chip {
    display: inline-block;
    input[type='radio'] + label {
        position: relative;
        color: $btn-link-border;
        line-height: 24px;
        border-radius: 25px;
        border: 1px solid $btn-link-border;
        padding: 3px 20px;
        margin-bottom: 0;
        cursor: pointer;
        &:before {
            display: none;
        }
    }
    input[type='radio']:checked + label {
        background-color: $btn-link-border;
        color: $white;
    }
}

.custom-upload {
    width: 100%;
    height: 155px;
    border-radius: 10px;
    border: 1px dashed rgba(15, 93, 153, 0.5);
    background: rgba(233, 236, 239, 0.2);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    &:read-only {
        opacity: 1;
        .upload-text {
            color: #b4b4b4;
        }
    }
    .upload-text {
        color: #b4b4b4;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        @media (min-width: $medium) {
            font-size: 14px;
        }
        span {
            display: block;
            margin-bottom: 10px;
        }
    }
    input[type='file'] {
        height: 100%;
        width: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
    }
}
.upload-form {
    margin-bottom: 20px;
    .form-label {
        width: 100%;
    }
    .btn-info {
        border-radius: 10px;
        background: #dff6ff;
        width: 100%;
        height: 50px;
        border-color: #dff6ff;
        color: #0f5d99;
        font-size: 13px;
        font-weight: 600;
        @media (min-width: $extra-extra-large) {
            height: 60px;
        }
        &:active {
            background: #47b5ff;
            color: #fff;
        }
    }
}
.input-with-icon {
    position: relative;
    .form-control {
        padding-right: 50px;
    }
    .icon {
        position: absolute;
        top: 13px;
        right: 20px;
        cursor: pointer;
    }
}
.radio-heading-wrapper {
    display: flex;
    margin-bottom: 30px;
    .custom-radio {
        &:not(:first-child) {
            margin-left: 55px;
        }
        .form-check {
            padding-left: 0;
        }
    }
}
.date-picker-wrapper {
    width: 100%;
    position: relative;
    label {
        width: 100%;
        margin-bottom: 0 !important;
    }
    .form-control {
        padding-right: 50px;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .icon {
        position: absolute;
        top: 12px;
        right: 15px;
        cursor: pointer;
        @media (min-width: $extra-extra-large) {
            top: 16px;
        }
    }
}
.verify-container {
    .verify-subtext {
        text-align: center;
        @media (min-width: $medium) {
            text-align: left;
        }
    }
    .form-group {
        text-align: left;
    }
    .help-text-container {
        p {
            color: #707070;
            font-size: 12px;
            font-style: normal;
            line-height: normal;
        }
        margin-bottom: 30px;
        text-align: left;
    }
}
.otp-container {
    input {
        background: $input-background;
        border: 1px solid $input-border-color;
        height: 40px;
        min-width: 40px;
        border-radius: 10px;
        color: $input-text;
        padding: 0 10px;
        font-size: 16px;
        margin-right: 13px;
        @media (min-width: $medium) {
            padding: 0 16px;
            font-size: 20px;
            height: 62px;
            min-width: 62px;
        }
        &:last-child {
            margin-right: 0;
        }
        &.success {
            border-color: #00d37a;
        }
        &.error {
            border-color: #ff6060;
        }
    }
    span {
        display: none;
    }
}
.char-limit {
    margin-top: 10px;
    color: #0f5d99;
    font-size: 12px;
    font-weight: 400;
}
.more-dropdown-form {
    .dropdown-toggle {
        padding: 0;
        &::after {
            display: none;
        }
        &:active:focus-visible {
            box-shadow: none;
        }
    }
}
.form-check {
    &.form-switch {
        .form-check-input {
            &[type='checkbox'] {
                position: relative;
                border: none;
                width: 44px;
                height: 24px;
                background-color: #e9e9e9;
                background-image: url(../../images/form-switch.svg);
                background-position: 2px;
                background-size: 20px;
                border-radius: 14px;
                margin-left: 0;
                margin-top: 1px;
                box-shadow: none;
                &:checked {
                    background-color: #34c759;
                    background-position: 22px;
                }
            }
        }
        .form-check-label {
            padding-left: 15px;
            &::before {
                display: none;
            }
        }
    }
}
.pac-container {
    z-index: 9999;
}
.custom-file-upload {
    position: relative;
    input[type='file'] {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
    }
    input[type='text'] {
        padding-right: 150px;
        height: 64px;
    }
    .btn {
        min-width: 140px !important;
        position: absolute;
        right: 10px;
        top: 10px;
        height: 44px;
        padding-top: 12px;
    }
}
.custom-datepicker{
    position: relative;
    .calender-icon{
        position: absolute;
        top: 18px;
        right: 20px;
    }
    &.data-range-picker{
        min-width: 220px;
        .react-datepicker-wrapper{
            width: 100%;
            .react-datepicker__input-container input{
                width: 100%;
            }
        }
        .calender-icon{
            top: 13px;
            right: 15px;
            @media (min-width: 1400px) {
                top: 18px;
                right: 20px;
            }
        }
        .react-datepicker__close-icon{
            padding-right: 12px;
        }
    }
}
