.top-section {
    position: fixed;
    top: 78px;
    left: 0;
    z-index: 1003;
    padding: 10px 30px;
    background: #fff;
    width: 100%;
    @extend %all-animation;
    @media (min-width: $large) {
        top: 71px;
        width: calc(100% - 170px);
        padding: 0 0 10px 40px;
        background: transparent;
    }
    @media (min-width: $extra-large) {
        padding: 10px 0 10px 40px;
        top: 51px;
        padding-left: 300px;
    }
    @media (min-width: $extra-extra-large) {
        padding-left: 323px;
    }
    .title {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 6px;
        word-break: break-word;
        @media (min-width: $large) {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        @media (min-width: $medium) {
            margin-bottom: 4px;
        }
    }
    .hand-image {
        height: 28px;
        width: 28px;
        margin-left: 15px;
    }
    .email {
        font-size: 14px;
        line-height: 20px;
        color: #222;
        margin: 0;
    }
    @media (max-width: 991px){
        position: initial;
        padding: 10px 0px;
    }
}
.tab-content {
    margin: 0 -15px;
}
.infinite-scroll-component {
    padding: 0 15px;
}
.inner-card {
    border-radius: 10px;
    border: 1px solid #eee;
    background: #fbfbfc;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
    line-height: normal;
    @media (min-width: $small) {
        margin-bottom: 30px;
        min-height: 88px;
    }
    .help-text {
        color: #0f5d99;
        font-size: 12px;
        font-weight: 400;
        margin-top: 6px;
        span {
            color: #ff6060;
            font-size: 12px;
        }
    }
    .content {
        padding-right: 8px;
        flex: 1;
        min-height: 56px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .content-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px;
        word-break: break-word;
        @media (min-width: $medium) {
            font-size: 18px;
        }
    }
    p {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
        @media (min-width: $medium) {
            font-size: 14px;
        }
    }
    .interactive {
        display: flex;
        justify-content: flex-end;
        min-width: 70px;
    }
    .more-dropdown-form {
        .dropdown-toggle {
            height: auto;
            width: auto;
            min-width: 24px;
        }
    }
    }
