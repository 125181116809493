.web-menu {
    position: fixed;
    left: -($sidebar-width);
    top: 0;
    height: 100%;
    width: $sidebar-small-width;
    display: none;
    z-index: 1003;
    @extend %all-animation;
    @media (min-width: $extra-large) {
        display: block;
        left: 0;
    }
    @media (min-width: $extra-extra-large) {
        width: $sidebar-width;
    }
    &.admin-menu {
        left: 0;
        display: block;
        .sidebar {
            width: 260px;
            ul {
                .link-item {
                    font-size: 18px;
                    padding: 15px 10px 15px 20px;
                }
            }
        }
    }
}
.sidebar {
    background: $left-nav-background;
    width: $sidebar-width;
    height: 100%;
    @media (min-width: $extra-large) {
        width: $sidebar-small-width;
    }
    @media (min-width: $extra-extra-large) {
        width: $sidebar-width;
    }
    .logo-container {
        text-align: center;
        width: 100%;
        padding: 30px 0 13px;
        img {
            max-width: 137px;
        }
    }
    ul {
        max-height: calc(100% - 205px);
        overflow: auto;
        padding-bottom: 20px;
        .list-item {
            position: relative;
        }
        .link-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 13px 1rem 13px 30px;
            position: relative;
            z-index: 2;
            border-radius: 0;
            width: 100%;
            color: $theme-text;
            font-size: 18px;
            @media (min-width: $extra-extra-large) {
                padding: 1rem 1rem 1rem 30px;
                font-size: 20px;
            }
            .image-wrapper {
                height: 44px;
                width: 44px;
                align-items: center;
                justify-content: center;
                display: flex;
                background-color: $white;
                border-radius: 12px;
                margin-right: 15px;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
                    2px 7px 16px 0px rgba(0, 0, 0, 0.04),
                    10px 27px 29px 0px rgba(0, 0, 0, 0.03),
                    22px 61px 39px 0px rgba(0, 0, 0, 0.02),
                    39px 108px 46px 0px rgba(0, 0, 0, 0.01),
                    60px 170px 50px 0px rgba(0, 0, 0, 0);
            }
            span {
                width: calc(100% - 60px);
                line-height: 1.4;
                &:first-child {
                    color: $nav-icon-color;
                    font-size: 24px;
                    margin-right: 10px;
                }
                &.same-text {
                    color: $theme-text;
                    font-size: 18px;
                    margin-right: 0;
                }
            }
            &.active {
                background: $nav-active-bg;
                @extend %all-animation;
                color: $white;
                .same-text {
                    color: $white;
                }
            }
        }
    }

    & + .full-width {
        width: calc(100% - 238px);
        margin-left: 238px;
    }
    .accordion {
        border: 0;
        box-shadow: none;
        .link-item {
            margin-bottom: 0;
            padding: 0;
            border: 0;
            background-color: transparent;
            .accordion-button {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 13px 1rem 13px 30px;
                position: relative;
                z-index: 2;
                border-radius: 0 !important;
                width: 100%;
                color: $theme-text;
                font-size: 18px;
                font-weight: 400;
                background: initial;
                box-shadow: none;
                &::after {
                    display: none;
                }
                @media (min-width: $extra-extra-large) {
                    padding: 1rem 1rem 1rem 30px;
                    font-size: 20px;
                }
            }
            .active {
                .accordion-button {
                    background: $nav-active-bg;
                    @extend %all-animation;
                    color: $white;
                }
            }
            .list-item {
                padding-left: 24px;
                .link-item {
                    span {
                        &.dot {
                            width: 10px;
                        }
                        width: calc(100% - 30px);
                    }
                }
            }
            .link-item {
                padding: 1rem 1rem 1rem 35px;
                font-size: 14px;
                color: #0f5d99;
                font-weight: 500;
                .dot {
                    width: 10px;
                    height: 10px;
                    margin-right: 15px;
                    border-radius: 50%;
                }
                &.active {
                    background-color: transparent;
                    color: #0f5d99;
                    .dot {
                        background-color: #47b5ff;
                    }
                }
            }
            &.active {
                background: $nav-active-bg;
                @extend %all-animation;
                color: $white;
            }
        }
        .accordion-header,
        .accordion-collapse {
            width: 100%;
        }
        .accordion-body {
            padding: 0;
        }
    }
}
.profile-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 440px;
    top: 0;
    right: -440px;
    background: #fff;
    transition: all 0.5s;
    z-index: 1005;
    border: 1px solid #e9ecef;
    &.active {
        right: 0;
        &::after {
            display: block;
        }
    }
    &::after {
        position: fixed;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        content: '';
        background: rgba(#000000, 0.4);
        z-index: 109;
        backdrop-filter: blur(2px);
        display: none;
    }
    .close-profile-icon{
        width: 40px;
        height: 40px;
        background: rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(10px);
        border-radius: 50%;
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer;
        z-index: 1005;
        padding: 11px 0;
        text-align: center;
    }
    .sidebar {
        width: 100%;
        background: #fff;
        position: relative;
        z-index: 1004;
        .login-inner {
            max-width: 100%;
            padding: 70px 37px;
            .btn-container {
                padding: 0 40px;
                margin-bottom: 0;
            }
        }
        .card {
            border-radius: 0;
            border: 0;
            padding: 0;
            box-shadow: none;
            max-height: 282px;
            margin-bottom: 42px;
            .card-img {
                border-radius: 0;
            }
            .video-container {
                width: 100%;
                height: 100%;
            }
            .full-width {
                max-height: 282px;
                object-fit: cover;
            }
            .card-img-overlay {
                display: inline-flex;
                justify-content: flex-end;
                align-items: center;
                color: #fff;
                flex-direction: column;
                padding: 10px 37px;
                text-align: center;
                height: auto;
                top: unset;
                right: unset;
                width: 100%;
                &::before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: '';
                    height: 134px;
                    width: 100%;
                    background: linear-gradient(
                        180deg,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(#000, 0.8) 100%
                    );
                }
            }
            .card-title {
                font-size: 20px;
                font-weight: 600;
                position: relative;
                z-index: 1;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                word-break: break-word;
            }
            .card-text {
                font-size: 14px;
                font-weight: 400;
                position: relative;
                z-index: 1;
                line-height: 20px;
            }
        }
        ul {
            .list-item {
                margin-bottom: 10px;
            }
            .link-item {
                padding: 14px 37px;
                font-size: 18px;
                position: relative;
                text-decoration: none;
                height: 52px;
                &.no-icon {
                    &::after {
                        display: none;
                    }
                }
                &:hover,
                &:focus,
                &:active {
                    color: $theme-text;
                }
                &.active {
                    background: #fff;
                    color: $theme-text;
                }
                .image-wrapper {
                    height: auto;
                    width: auto;
                    padding: 0;
                    background: transparent;
                }
                &::after {
                    position: absolute;
                    right: 37px;
                    top: 14px;
                    content: '';
                    height: 24px;
                    width: 24px;
                    background: url('../../images/chevron-right.svg') no-repeat
                        center;
                }
            }
        }
        .btn-container {
            padding: 24px 80px;
            .btn {
                height: 60px;
            }
        }
    }
}
.switcher {
    padding: 0px 30px 0px 37px;
    .label {
        color: #222;
        font-size: 18px;
        font-weight: 400;
        line-height: normal;
    }
    &.with-image {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        img {
            margin-right: 15px;
        }
        .form-switch {
            padding-left: 1rem;
        }
    }
}
