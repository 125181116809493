.react-datepicker {
  font-family: 'Poppins', sans-serif !important;
  border: 1px solid #E9ECEF !important;
  &__time-container{
    width: 150px !important;
  }
}
.react-datepicker-popper .react-datepicker__triangle{
  &::after,
  &::before{
    display: none;
  }
}
.react-datepicker-popper[data-placement^=bottom]{
  padding-top: 0 !important;
}
.react-datepicker-popper[data-placement^=top]{
  padding-bottom: 0 !important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
  width: 150px !important;
  text-align: left !important;
  ul.react-datepicker__time-list{
    li.react-datepicker__time-list-item{
      padding: 7px 10px !important;
      &--selected{
        background-color: #47B5FF !important;
      }
      &:hover{
        background-color: #E1F3FF !important;
      }
    }
  }
}
.react-datepicker__header{
  background-color: #E1F3FF !important;
  border-color: #E1F3FF !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
  margin-left: -18px !important;
}

.react-datepicker__day{
  &--selected{
    background-color: #47B5FF !important;
  }
}
.react-datepicker__navigation-icon{
  &::before{
    border-width: 2px 2px 0 0 !important;
    border-color: #0F5D99 !important;
  }
}
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text{
  &:hover{
    background-color: #E1F3FF !important;
    color: #000;
  }
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected{
  &:hover{
    background-color: #47B5FF !important;
  }
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header{
  font-weight: 500 !important;
}
