.dashboard-cards {
    margin-top: 10px;
    margin-left: -15px;
    margin-right: -15px;
    @media (min-width: $medium) {
        margin-top: 0;
    }
    > .col,
    > [class*='col-'] {
        padding-right: 15px;
        padding-left: 15px;
    }
}
.card {
    border: transparent;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
        2px 7px 16px 0px rgba(0, 0, 0, 0.04),
        10px 27px 29px 0px rgba(0, 0, 0, 0.03),
        22px 61px 39px 0px rgba(0, 0, 0, 0.02),
        39px 108px 46px 0px rgba(0, 0, 0, 0.01),
        60px 170px 50px 0px rgba(0, 0, 0, 0);
    flex-direction: row;
    @media (min-width: $large) {
        margin-bottom: 40px;
    }
    &.pink {
        background: #fff3f3;
    }
    &.yellow {
        background: #fff5dd;
    }
    &.blue {
        background: #e3f4ff;
    }
    &.green {
        background: #d1f9e2;
    }
    .card-image {
        border-radius: 12px;
        background: $white;
        height: 44px;
        min-width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
            2px 7px 16px 0px rgba(0, 0, 0, 0.04),
            10px 27px 29px 0px rgba(0, 0, 0, 0.03),
            22px 61px 39px 0px rgba(0, 0, 0, 0.02),
            39px 108px 46px 0px rgba(0, 0, 0, 0.01),
            60px 170px 50px 0px rgba(0, 0, 0, 0);
        margin-right: 20px;
    }
    .card-content {
        line-height: 1.5;
        .card-title {
            font-size: 28px;
            font-weight: 600;
            margin-bottom: 0;
        }
        .card-des {
            font-size: 16px;
        }
    }
}
.list-box {
    background: #e2f7ff4d;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding-top: 12px;
    margin-bottom: 30px;

    .list-title {
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 5px;
        padding: 0 14px;
    }
    .list-sub-title {
        font-size: 14px;
        margin-bottom: 25px;
        padding: 0 14px;
    }
    ul {
        li {
            padding: 13px 18px;
            border-bottom: 1px solid #e3e3e3;
            &:last-child {
                border-bottom: 0;
            }
            &.main-text {
                .text {
                    font-size: 13px;
                }
            }
            .sr-number {
                font-size: 13px;
                font-weight: 500;
                min-width: 65px;
                display: inline-block;
            }
            .text {
                font-size: 10px;
                font-weight: 500;
                text-transform: capitalize;
                display: inline-block;
            }
        }
    }
}
.rating-card {
    border-radius: 10px;
    background: #222;
    color: $white;
    padding: 17px 21px 16px 16px;
    margin-bottom: 30px;
    .rating-title {
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.154px;
        margin-bottom: 5px;
    }
    .chart-space {
        display: flex;
        align-items: flex-end;
        @media (min-width: $medium) {
            flex-wrap: wrap;
        }
        @media (min-width: $large) {
            flex-wrap: nowrap;
        }
        .chart-title {
            color: #e2f7ff;
            font-size: 20px;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 3px;
        }
        .chart-sub-title {
            color: #87888c;
            font-size: 11px;
            font-weight: 500;
            line-height: normal;
            @media (min-width: $medium) {
                margin-bottom: 20px;
            }
            @media (min-width: $large) {
                margin-bottom: 0;
            }
        }
        .chart-container {
            min-height: 88px;
            flex: 1;
            text-align: center;
            .semi-donut-model-2 {
                width: 130px;
                height: 65px;
                position: relative;
                text-align: center;
                font-size: 22px;
                font-weight: 600;
                border-radius: 150px 150px 0 0;
                overflow: hidden;
                color: #fff;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                box-sizing: border-box;
                margin: 0 auto;
                &:before,
                &:after {
                    content: '';
                    width: 130px;
                    height: 65px;
                    border: 25px solid #e2f7ff;
                    border-top: none;
                    position: absolute;
                    transform-origin: 50% 0% 0;
                    border-radius: 0 0 300px 300px;
                    box-sizing: border-box;
                    left: 0;
                    top: 100%;
                }
                &:before {
                    border-color: #535353;
                    transform: rotate(180deg);
                }
                &:after {
                    z-index: 3;
                    animation: 1s fillGraphAnimation ease-in;
                    transform: rotate(
                        calc(1deg * (var(--rotation-degree) * 1.8))
                    );
                }
            }
        }
    }
}
.help-text-dashboard {
    color: #222;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    margin-bottom: 20px;
}
