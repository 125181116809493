.page-header {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 30px;
    &.justify-content-space-between {
        display: flex;
        justify-content: space-between;
    }
}

.admin-categories {
    .nav-tabs {
        margin-top: 0;

        .nav-link {
            font-weight: 500;
        }
    }

    .tab-content {
        margin: 0;
    }

    .tab-pane {
        display: flex;
        width: 100%;
        align-items: flex-start;
        .infinite-scroll-component__outerdiv {
            width: 100%;
        }
    }
}

.categories-card {
    background: #fff;
    // max-width: 470px;
    width: 50%;

    & + .categories-card {
        margin-left: -1px;
    }

    .card-header {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #eee;
        position: relative;
        z-index: 1;

        .header-title {
            font-size: 16px;
            font-weight: 600;
        }

        .btn-link {
            color: #0f5d99;
            font-size: 14px;
            font-weight: 600;
            text-decoration: none;
            padding: 0;
            height: auto;
        }
    }
}

.categories-list {
    border: 1px solid #eee;
    border-top: 0;
    margin-top: -1px;
    position: relative;
    z-index: 2;
    background: #fff;

    .list-item {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        min-height: 60px;
        border-bottom: 1px solid #eeeeee;

        &:last-child {
            border-bottom: 0;
        }

        &.active,
        &:active {
            background: #dff6ff;
        }
    }

    .content {
        display: flex;
        flex-direction: column;

        .title {
            line-height: 20px;
            word-break: break-word;
            padding-right: 20px;
        }

        .text {
            color: #bbb;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            word-break: break-word;
            padding-right: 20px;
        }
    }

    .image-container {
        cursor: pointer;
    }
}

.header-with-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    p {
        margin-top: 10px;
    }
}

.back-btn-large {
    font-size: 28px;
    font-weight: 500;
    color: #222;
    display: inline-block;

    img {
        margin-right: 15px;
    }
}

.list-group {
    padding-bottom: 30px;

    .list-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        position: relative;

        &::after {
            content: '';
            display: inline-block;
            width: 100%;
            position: absolute;
            height: 1px;
            background-color: #eee;
            z-index: -1;
            top: 50%;
            left: 0;
        }

        .list-title {
            font-size: 16px;
            font-weight: 400;
            background-color: #fff;
            padding-right: 13px;
        }

        .btn-container {
            background-color: #fff;
            padding: 0 0 0 20px;

            .btn-link {
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
                padding: 0;
                height: auto;

                &:not(:first-child) {
                    margin-left: 30px;
                }
            }
        }
    }

    .list-container {
        margin: 0 -5px;

        .avatar-chips {
            border-radius: 50px;
            border: 1px solid #eee;
            background: #fff;
            padding: 5px 20px 5px 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 5px 10px;

            .user-avatar {
                height: 40px;
                width: 40px;
                border-radius: 100%;
                object-fit: cover;
                margin-right: 10px;
                cursor: pointer;
            }

            .avatar-delete {
                margin-left: 20px;
                height: 12px;
                width: 12px;
                margin-top: -8px;
                cursor: pointer;

                img {
                    height: 12px;
                    width: 12px;
                }
            }
        }
    }
}

.admin-business-categories {
    .tab-content {
        border-top: 1px solid #eee;
        padding-top: 40px;
        margin: 0;

        .nav-tabs {
            margin-bottom: 20px;
            margin-top: -10px;
        }

        .tab-content {
            border-top: 0;
            padding-top: 0;
        }

        .back-btn {
            margin-bottom: 8px;
            display: inline-block;
            position: relative;
            top: -16px;
            left: 0;

            img {
                margin-right: 10px;
                width: 16px;
            }
        }

        .radio-container {
            margin-bottom: 30px;
        }
    }

    .nav-tabs {
        .nav-item {
            &:not(:first-child) {
                margin-left: 34px;
            }
        }

        .nav-link {
            font-weight: 500;
        }
    }

    .detail-container {
        display: flex;
        margin-bottom: 40px;

        .horizontal-list {
            flex: 1;
            margin-right: 30px;
            border-radius: 10px;
            border: 1px solid #e9ecef;

            .list {
                display: flex;
                align-items: center;
                padding: 15px 25px;
                color: #c5c5c5;
                font-size: 14px;
                font-weight: 400;

                &:not(:last-child) {
                    border-bottom: 1px solid #eeeeee;
                }

                .list-title {
                    color: #000;
                    min-width: 200px;
                }
            }
        }

        .image-container {
            width: 293px;
            height: 166px;
            border-radius: 20px;

            img {
                border-radius: 20px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                &.play-icon {
                    border-radius: 0;
                    width: 48px;
                    height: 48px;
                }
            }
            .video-container,
            .cursor-pointer {
                width: 100%;
                height: 100%;
                display: inline-block;
                position: relative;
            }
        }
    }

    .inner-heading {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .check-title {
        color: #222;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .accordion-container {
        margin-bottom: 40px;
    }

    .check-list-container {
        &:not(:last-child) {
            padding-bottom: 10px;
        }
    }

    .list-container {
        display: flex;
        flex-wrap: wrap;

        .bullet-list {
            min-width: 150px;
            padding-left: 20px;
            position: relative;
            margin-bottom: 20px;

            &::before {
                content: '';
                display: inline-block;
                height: 4px;
                width: 4px;
                border-radius: 100%;
                background: #222;
                position: absolute;
                top: 7px;
                left: 7px;
            }
        }
    }

    .big-label {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .custom-radio-chip input[type='radio']:checked:read-only + label {
        background-color: #222222;
        cursor: default;
        border-color: #222;

        &::after {
            background-color: #222222;
        }
    }

    .form-group {
        margin-bottom: 40px;

        p {
            color: #bbb;
            line-height: 23px;
            margin-bottom: 20px;
            padding-right: 40px;
        }
    }

    .chip-container {
        margin: 0 0 40px;

        .chip {
            color: #222;
            font-size: 16px;
            font-weight: 300;
            min-width: 240px;
            margin-bottom: 7px;

            span {
                font-weight: 600;
            }
        }
    }

    .divider {
        margin-bottom: 30px;
    }

    .last-line {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 4px;
    }

    .btn-deactive {
        color: #ff6060;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        padding: 0;
        height: auto;
        text-decoration: none;
        margin-bottom: 30px;

        &:focus,
        &:hover,
        &:active {
            color: darken(#ff6060, 10);
        }

        &.text-primary {
            color: $theme-color;

            &:focus,
            &:hover,
            &:active {
                color: darken($theme-color, 10);
            }
            &.two-btn {
                padding-right: 30px;
            }
        }
    }

    #special-tab-tabpane-Events,
    #special-tab-tabpane-localSpecial {
        margin-left: -15px;
        margin-right: -15px;
    }
}
