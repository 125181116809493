.spacial-tab-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    position: relative;
    margin-top: 50px;

    @media (min-width: $small) {
        margin-top: 0;
    }

    &.event-tab-container {
        margin-top: 35px;

        @media (min-width: $small) {
            margin-top: 0;
        }
        &.less-top-margin {
            margin-top: -30px;
            @media (min-width: $small) {
                margin-top: 0;
            }
        }
    }

    &.location-tab-container {
        h4 {
            margin-top: 15px;

            @media (min-width: $small) {
                margin-top: 0;
                min-height: 50px;
                display: flex;
                align-items: center;
            }
        }

        .absolute-button {
            @media (min-width: $small) {
                max-width: 210px;
            }
        }
    }

    .absolute-button {
        position: absolute;
        right: 0;
        top: -50px;
        min-width: 190px;
        width: 100%;

        img {
            margin-right: 5px;
        }

        @media (min-width: $small) {
            max-width: 190px;
            top: 0;
        }
    }
}

.nav-tabs {
    border-bottom: 0;
    margin: 10px 0 38px;

    .nav-link {
        border: 0;
        border-bottom: 3px solid transparent;
        padding: 5px 0;
        color: $theme-text;
        opacity: 0.5;
        font-size: 16px;
        font-weight: 500;

        &:hover {
            opacity: 1;
            border-color: transparent;
        }

        &.active {
            opacity: 1;
            border-color: #47b5ff;
        }
    }

    .nav-item {
        &:not(:first-child) {
            margin-left: 45px;
        }
    }
}

.special-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    position: relative;

    .card-image {
        height: 170px;
        width: 100%;
        border-radius: 20px;
        box-shadow: 0.11923645436763763px 0.2444722205400467px
                0.7888000011444092px 0px rgba(0, 0, 0, 0.02),
            0.5330570936203003px 1.0929346084594727px 1.8223999738693237px 0px
                rgba(0, 0, 0, 0.04),
            1.3256287574768066px 2.7179558277130127px 4.161600112915039px 0px
                rgba(0, 0, 0, 0.04),
            2.581118583679199px 5.292104721069336px 8.867199897766113px 0px
                rgba(0, 0, 0, 0.06),
            4.383693218231201px 8.98794937133789px 17px 0px rgba(0, 0, 0, 0.08);
        margin-bottom: 10px;
        background: #dff6ff;

        @media (min-width: $medium) {
            height: 155px;
        }

        @media (min-width: 1600px) {
            height: 200px;
        }

        .video-container,
        .cursor-pointer {
            height: 100%;
            display: inline-block;
            width: 100%;
            position: relative;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;

            &.play-icon {
                height: 48px;
                width: 48px;
                border-radius: 0;
            }
        }
    }

    .card-title {
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        img {
            display: none;
        }
        &.with-icon {
            position: relative;
            padding-right: 26px;
            z-index: 9;
            img {
                display: inline-block;
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 20px;
            }
        }
    }

    .card-text {
        color: $theme-text;
        font-size: 13px;
        margin-bottom: 10px;

        @media (min-width: $extra-extra-large) {
            font-size: 14px;
        }
    }
    .card-info-text {
        margin-top: 5px;
        font-style: italic;
        font-weight: 300;
        span {
            color: #0f5d99;
            font-weight: 600;
        }
    }

    .more-dropdown {
        display: flex;
        align-items: center;
        position: absolute;
        top: 10px;
        right: 10px;

        .dropdown {
            .dropdown-toggle {
                padding: 0;
                border-radius: 40px;
                background: rgba(0, 0, 0, 0.25);
                backdrop-filter: blur(10px);
                min-width: 40px;
                height: 40px;

                &::after {
                    content: '';
                    background: url('../../images/more-vertical.svg') no-repeat
                        center;
                    border: 0;
                    height: 24px;
                    min-width: 24px;
                }
            }

            .dropdown-menu {
                min-width: 175px;
            }
        }
    }
}

.special-card.three-tile-list {
    cursor: pointer;
    &.no-detail {
        cursor: auto;
    }
}

.special-card {
    cursor: pointer;
    &.no-detail {
        cursor: auto;
    }
}

.absolute-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: -60px;
    right: 0;
    width: 100%;

    @media (min-width: $small) {
        top: 0;
        max-width: 390px;
    }

    .absolute-button {
        position: static;
        padding-left: 5px;
        padding-right: 5px;
        min-width: calc(50% - 5px);
        max-width: 190px;
        width: auto;

        &.btn-outline-primary {
            img {
                display: none;
            }
        }
    }
}

.info-icon {
    cursor: pointer;
}
@media (max-width:  576px) {
    .events-tab-container-event {
        padding-top: 60px;
        margin-top: 30px;
        .absolute-btn-container {
            top: 0;
        }
    }
}

