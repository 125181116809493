.notification-container {
  padding-bottom: 23px;
  .page-title {
    color: #222;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 30px;
    @media (min-width: $medium) {
      font-size: 24px;
    }
  }
  .infinite-scroll-component{
    padding: 0;
  }
  .notification-inner-content{
    border-radius: 20px;
    border: 1px solid #EEE;
    padding: 9px 0 7px;
  }
  .list{
    padding: 11px 20px 13px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    @media (min-width: $medium) {
      padding: 21px 30px 23px;
    }
    &:not(:last-child){
      border-bottom: 1px solid #EEEEEE;
    }
    .list-content{
      margin-bottom: 15px;
      @media (min-width: $medium) {
        margin-bottom: 0;
        padding-right: 35px;
        flex: 1;
      }
      .title{
        color: #202020;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 6px;
        @media (min-width: $medium) {
          font-size: 18px;
        }
      }
      p{
        margin-bottom: 0;
        color: #202020;
        font-size: 12px;
        font-weight: 400;
        @media (min-width: $medium) {
          font-size: 14px;
        }
      }
    }
    .list-time{
      color:  #BBB;
      font-size: 12px;
      font-weight: 400;
      text-align: right;
      width: 100%;
      @media (min-width: $medium) {
        width: 100px;
      }
    }
  }
}
