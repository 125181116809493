* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    text-rendering: auto;
}

ul,
ol,
li {
    list-style-type: none;
    padding: 0;
}

html,
body {
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400;
    color: $theme-text;
    -webkit-tap-highlight-color: transparent !important;
}

*:before,
*:after {
    -webkit-tap-highlight-color: transparent !important;
}
#__next {
    height: 100%;
}

.btn:focus {
    outline: none;
}
body,
ul,
figure {
    padding: 0;
    margin: 0;
}
li {
    list-style: none;
}
a {
    color: $link-color;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
        outline: none;
        text-decoration: none;
        cursor: pointer;
        color: darken($link-color, 10);
    }
}
p {
    font-size: 14px;
}

#root {
    height: 100%;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.text-danger {
    color: $danger !important;
}
.text-primary {
    color: $theme-color !important;
}
.word-break {
    word-break: break-word !important;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}
.main {
    height: 100%;
    flex: 1;
    margin-left: 0;
    position: relative;
    @extend %all-animation;
    @media (min-width: $extra-large) {
        margin-left: $sidebar-small-width;
    }
    @media (min-width: $extra-extra-large) {
        margin-left: $sidebar-width;
    }
    &.admin-main {
        margin-left: $sidebar-small-width;
        min-width: 1000px;
    }
    &.subadmin-main {
        margin-left: auto;
    }
}
.menu-open {
    overflow: hidden;
}
.menu-close {
    overflow: visible;
}
.main-menu {
    position: fixed;
    left: -80%;
    width: 80%;
    top: 0;
    height: 100%;
    z-index: 1005;
    background: $white;
    @extend %all-animation;
    @media (min-width: $medium) {
        left: -($sidebar-width);
        width: $sidebar-width;
    }
    @media (min-width: $extra-large) {
        left: 0;
        display: none;
    }
    &.active {
        left: 0;
    }
    .sidebar {
        width: 100%;
    }
}
.menu-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($black, 0.4);
    z-index: 1004;
    opacity: 0;
    visibility: hidden;
    @extend %all-animation;
    display: none;
    &.active {
        opacity: 1;
        visibility: visible;
        display: block;
        @media (min-width: $extra-large) {
            display: none;
            opacity: 0;
            visibility: hidden;
        }
    }
}
.close-sidebar {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 99;
    @media (min-width: $extra-large) {
        display: none;
    }
}
.header-menu {
    position: fixed;
    z-index: 1003;
    top: 25px;
    left: 25px;
    @media (min-width: $large) {
        left: 35px;
        top: 40px;
    }
    @media (min-width: $extra-large) {
        display: none;
    }
}
.main-container {
    display: flex;
    height: 100%;
}
.inner-main-container {
    padding: 200px 30px 0;
    @media (min-width: $medium) {
        padding: 196px 40px 0;
    }
    &.home-layout {
        padding-top: 140px;
        @media (min-width: $medium) {
            padding-top: 196px;
        }
    }
    @media (max-width: 991px){
        padding-top: 78px!important;
    }
}
.sub-admin-main-container {
    padding-top: 104px;
}
.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
}

.divider {
    width: 100%;
    height: 1px;
    background: #f0f0f0;
    margin: 0 0 40px;
    &.border-2 {
        height: 2px;
    }
}
%all-animation {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.dropdown {
    .dropdown-toggle {
        color: $card-dropdown-icon;
        font-size: 26px;
        line-height: 10px;
        height: 50px;
        min-width: 50px;
        align-items: center;
        justify-content: center;
        border: solid 1px transparent;
        box-shadow: none;
        @extend %all-animation;
    }
    .dropdown-menu {
        border-radius: 10px;
        border: 1px solid transparent;
        background: #fff;
        padding: 10px 0;
        min-width: 200px;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04),
            2px 7px 16px 0px rgba(0, 0, 0, 0.04),
            10px 27px 29px 0px rgba(0, 0, 0, 0.03),
            22px 61px 39px 0px rgba(0, 0, 0, 0.02),
            39px 108px 46px 0px rgba(0, 0, 0, 0.01),
            60px 170px 50px 0px rgba(0, 0, 0, 0);
        .dropdown-item {
            font-size: 16px;
            padding: 10px 30px;
            color: $theme-text;
            align-items: center;
            display: inline-flex;
            &:hover {
                background: #fbfbfc;
            }
            img {
                margin-right: 20px;
            }
        }
    }
}
.alert {
    position: fixed;
    top: 60px;
    left: 0;
    max-width: 625px;
    width: 100%;
    min-height: 60px;
    border-radius: 12px;
    border: 1px solid;
    display: flex;
    align-items: center;
    z-index: 1007;
    padding: 20px;
    @media (min-width: $medium) {
        left: 25%;
        left: 50%;
        transform: translate(-50%);
    }
    @media (min-width: $extra-large) {
        left: 38%;
        transform: translate(0);
    }
    .alert-heading {
        color: #27303a;
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
        text-transform: capitalize;
        margin-bottom: 4px;
    }
    p {
        color: #2f3f53;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.6;
    }
    img {
        margin-right: 16px;
    }
    &.alert-dismissible {
        padding-right: 50px;
        .btn-close {
            top: 50%;
            transform: translate(-50%, -50%);
            right: 5px;
            padding: 5px;
            background-size: 50%;
        }
    }
    &.alert-success {
        border: 1px solid #48c1b5;
        background: #f6fff9;
    }
    &.alert-info {
        border: 1px solid #f7d9a4;
        background: #fff8ec;
    }
    &.alert-danger {
        border: 1px solid #f4b0a1;
        background: #fff5f3;
    }
}
.chip {
    border-radius: 10px;
    background: #f7f7f7;
    color: #0f5d99;
    display: inline-block;
    padding: 11px 20px;
    margin-bottom: 30px;
    &.primary {
        border-radius: 25px;
        border: 1px solid #0f5d99;
        padding: 6px 10px;
        margin-bottom: 5px;
        background: #fff;
        margin-left: 2.5px;
        margin-right: 2.5px;
        &:not(:first-child) {
            margin-left: 2.5px;
        }
        &.dark {
            border-color: #222;
            color: #222;
            margin-bottom: 10px;
        }
    }
    .close {
        display: inline-block;
        margin-left: 15px;
        cursor: pointer;
        &.small {
            height: 10px;
            width: 10px;
        }
    }
}
.back-with-text {
    display: flex;
    align-items: center;
    margin: 20px 0;
    @media (min-width: $medium) {
        margin-top: 0;
    }
    span {
        display: inline-block;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin-left: 20px;
        @media (min-width: $medium) {
            font-size: 24px;
        }
    }
}
.no-data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    .no-data-inner {
        text-align: center;
    }
    .no-data-image {
        margin-bottom: 40px;
    }
    h3 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 16px;
    }
    p {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 23px;
    }
    .absolute-btn-container {
        position: static;
        min-width: 390px;
    }
}
.center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    flex-direction: column;
    width: 100%;
    margin: 20px auto;
    height: calc(100% - 40px);
}
.error-container {
    text-align: center;
    img {
        margin-bottom: 10px;
        width: auto;
        &.under-image {
            max-height: 150px;
            @media (min-width: $medium) {
                max-height: 225px;
            }
        }
        &.no-result {
            max-height: 150px;
            @media (min-width: $medium) {
                max-height: 218px;
            }
        }
        &.error-image {
            max-height: 150px;
            @media (min-width: $medium) {
                max-height: 238px;
            }
        }
    }
    h3 {
        color: #222;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    p {
        color: #2e2e2e;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 0;
    }
    .btn {
        margin: 40px auto 0;
        min-width: 190px;
    }
}
.Toastify {
    &__toast-container {
        border: 0;
        position: relative;
        padding: 15px 15px 0 !important;
        max-width: 500px;
        width: 100% !important;
        @media screen and (min-width: 768px) {
            padding: 0 !important;
        }
    }
    &__toast {
        margin-bottom: 0 !important;
        padding: 0 !important;
        background: transparent !important;
        border-radius: 12px !important;
    }
    &__toast-body {
        padding: 0 !important;
    }
    &__close-button {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        right: 16px;
        > svg {
            height: 18px !important;
        }
    }
    .toast-inner {
        width: 100%;
        max-width: 500px;
        padding: 15px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding-right: 41px;
        h2 {
            color: #27303a;
            font-size: 14px;
            font-weight: 600;
            line-height: 140%;
            text-transform: capitalize;
        }
        p {
            color: #2f3f53;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.6;
            margin-top: 4px;
        }
        img {
            margin-right: 16px;
        }
        &.success {
            border: 1px solid #48c1b5;
            background: #f6fff9;
        }
        &.info {
            border: 1px solid  #0F5D99;
            background: #EDF7FF;
        }
        &.danger {
            border: 1px solid #f4b0a1;
            background: #fff5f3;
        }
    }
}

.custom-select {
    [class*='option'] {
        &[aria-disabled='true'] {
            opacity: 0.3;
            pointer-events: none;
        }
    }
}

// Admin
.admin-main-container {
    padding: 140px 40px 20px;
}
.ReactCrop__child-wrapper > img,
.ReactCrop__child-wrapper > video {
    max-height: 300px !important;
    @media screen and (min-width: 768px) {
        max-height: 400px !important;
    }
}
.input-with-button {
    position: relative;
    .btn {
        position: absolute;
        top: 38px;
        right: 20px;
        @media (min-width: $extra-extra-large) {
            top: 44px;
        }
    }
    .btn-link {
        color: #0f5d99;
        // opacity: 0.5;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        padding: 0;
        text-decoration: none;
        height: auto;
    }
    .form-control {
        padding-right: 60px;
    }
}
.outer-border-container {
    border-radius: 10px;
    border: 1px solid #eee;
    background: #fff;
    padding: 30px 30px 0;
}
.badge {
    display: flex;
    padding: 7px 12px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    &.text-bg-info {
        color: #0f5d99 !important;
        background: #dff6ff !important;
    }
}
.btn.label-with-clear {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    height: auto;
    text-decoration: none;
    position: absolute;
    right: 0;
    top: 0;
    &:disabled,
    &.disabled {
        opacity: 0.5;
    }
}
.filter-header {
    margin-bottom: 30px;
    .page-header{
        margin-bottom: 0;
    }
    .col-md-9 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .form-control,
        .form-group {
            max-width: 235px;
            margin-bottom: 0;
            margin-right: 20px;
        }
        .react-datepicker__input-container input {
            padding: 14px 20px 16px 20px;
            justify-content: center;
            align-items: flex-end;
            border-radius: 10px;
            border: 1px solid #e9ecef;
            background: rgba(233, 236, 239, 0.2);
            height: 50px;
            @media (min-width: 1400px) {
                height: 60px;
            }
        }
    }
}
