header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 78px;
  padding: 0 30px;
  background: linear-gradient(180deg, #DFF6FF 0%, #F8FDFF 100%), #FFF;
  @media (min-width: $large) {
    z-index: 1002;
    padding: 0 40px;
		height: 146px;
	}
  &.admin-header{
    height: 100px;
    padding: 0 40px;
    .menu{
      justify-content: space-between;
      width: 100%;
      padding-left: 260px;
      h1{
        color: #000;
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
  &.sub-admin-header{
    background: #fff;
    height: 80px;
  }
  .container-fluid {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    height: 100%;
  }
  .menu{
    display: flex;
    align-items: center;
    .nav-link{
      margin-right: 30px;
    }
    .notification-badge{
      position: relative;
      .badge{
        position: absolute;
        top: -1px;
        right: -1px;
        height: 11px;
        width: 11px;
        background-color: #FF0000;
        display: inline-block;
        border-radius: 50%;
        padding: 0;
      }
    }
  }
  .user-dropdown{
    display: flex;
    align-items: center;
    .profile-pic{
      height: 32px;
      width: 32px;
      margin-right: 20px;
    }
    .profile-menu-btn{
      cursor: pointer;
      position: relative;
      z-index: 103;
    }
  }
}
.admin-dropdown{
  .dropdown-toggle{
    height: auto;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    color: #000;
    text-decoration: none;
    padding-right: 29px;
    &::after{
      border: 0;
      height: 24px;
      width: 24px;
      display: inline-block;
      content: '';
      background: url(../../images/chevron-down.svg) no-repeat center;
      position: absolute;
      top: -2px;
      right: 0;
    }
  }
  .dropdown-menu{
    border-color: #E9ECEF;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 2px 7px 16px 0px rgba(0, 0, 0, 0.04), 10px 27px 29px 0px rgba(0, 0, 0, 0.03), 22px 61px 39px 0px rgba(0, 0, 0, 0.02), 39px 108px 46px 0px rgba(0, 0, 0, 0.01), 60px 170px 50px 0px rgba(0, 0, 0, 0.00);
  }
}
